// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 18px 0;
	color: $white;
	transition: background-color 0.5s, padding 0.5s;

	&.sticky {
		padding: 8px 0;
		background-color: $quaternary;
		box-shadow: $shadow;
		color: $primary;

		.logo:not(.logo-sticky) {
			display: none;
		}

		.logo-sticky {
			display: block;
		}
	}

	[class^="container-"] {
		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;

				@include media-breakpoint-up(xl) {
					padding: 0 45px;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		border-right: 1px solid;
		border-color: inherit;

		.navbar-toggler {
			display: flex !important;
			align-items: center;
			padding: 0 15px 0 0;
			border-radius: 0;
			border: 0;
			color: inherit;
			line-height: 36px;
			transition: color 0.5s;

			.navbar-toggler-icon {
				font-size: 20px;
			}

			.navbar-toggler-label {
				margin-left: 10px;
				font-size: 14px;
				font-weight: 700;

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			&:hover {
				color: $tertiary;
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(xs) {
			max-width: 150px;
		}

		max-width: 166px;
		max-height: 44px;
		margin: 0 auto 0 15px;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	.logo-sticky {
		display: none;
	}

	// social
	.social {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		ul {
			margin-left: 0;

			li {
				@include media-breakpoint-up(md) {
					&:first-of-type {
						display: none;
					}
				}

				a {
					display: block;
					padding: 0 0.5rem;
					color: inherit;
					line-height: 36px;

					span {
						display: none;
					}

					&:hover {
						color: $tertiary;
					}
				}
			}
		}
	}

	// languages
	.languages {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		margin-left: 10px;

		.language {
			.nav-item {
				line-height: 36px;

				.nav-link {
					img {
						width: 17px;
						height: 17px;
					}
				}
			}
		}
	}

	// logo-brasserie
	div.logo-brasserie {
		@include media-breakpoint-down(xs) {
			display: none;
		}

		ul {
			z-index: 1;
			position: relative;
			width: 150px;
			margin-left: 15px;
			transition: width 0.5s;

			a {
				display: block;

				img {
					position: absolute;
					top: -30px;
				}

				span {
					display: none;
				}
			}
		}
	}

	&.sticky {
		.logo-brasserie {
			ul {
				width: 100px;
			}
		}
	}

	// book-button
	.book-button {
		ul {
			margin-left: 15px;
			padding-left: 15px;
			border-left: 1px solid;
			border-color: inherit;
			font-size: 14px;
			font-weight: 700;
			line-height: 34px;

			li {
				a {
					color: inherit;
					text-decoration: none;

					&:hover {
						color: $tertiary;
					}
				}
			}
		}
	}
}

&.menu-overlay-active {
	.header {
		padding: 8px 0;
		background-color: $quaternary;
		box-shadow: $shadow;
		color: $primary;

		.logo:not(.logo-sticky) {
			display: none;
		}

		.logo-sticky {
			display: block;
		}

		@include media-breakpoint-down(sm) {

			.social,
			.languages {
				position: fixed;
				bottom: 15px;
				display: block;
				justify-content: space-between;
			}

			.social {
				left: 15px;
			}

			.languages {
				right: 15px;
			}
		}
	}
}
