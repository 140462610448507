// menu
.menu {
	transform: translateY(-100vh);
	background: $quaternary;
	transition: 0.5s;
	opacity: 0;
	&.collapsing {
		transition: none;
	}
	&.show {
		z-index: -1;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		background: $quaternary;
		transform: translateY(0);
	}

	// navbar-nav
	.navbar-nav {
		justify-content: center;
		height: 100%;
		padding: 60px 0;

		.nav-item {
			font-size: 18px;
			font-weight: 700;
			text-align: center;

			@include media-breakpoint-up(xl) {
				font-size: 24px;
			}

			.nav-link {
				color: $primary;
				transition: color 0.5s;

				&:hover {
					color: $tertiary;
				}

				&.dropdown-toggle {
					pointer-events: none;
				}
			}

			&.active {
				> .nav-link {
					color: $tertiary;
				}
			}

			.dropdown-menu {
				position: static !important;
				transform: none !important;
				display: block;
				margin: 0;
				padding: 0;
				border: none;
				border-radius: 0;
				box-shadow: none;
				background-color: transparent;

				&::before,
				&::after {
					display: none;
				}

				.nav-item {
					font-size: 16px;
					font-weight: 400;

					@include media-breakpoint-up(xl) {
						font-size: 20px;
					}
				}
			}
		}
	}

}
