// custom-list
.custom-list {
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 1.5rem;

        &::before {
            content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-awesome;
            color: $primary;
            font-weight: 900;
        }
    }
}

// custom-list-extra
.custom-list-extra {
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 1.5rem;

        &::before {
            content: '\f058';
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-awesome;
            color: $primary;
            font-weight: 400;
        }
    }
}