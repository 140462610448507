// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #84a98c;
$green-light: #eaf0f0;
$green-dark: #354f52;
$yellow: #efa91d;
$white: #fff;
$black: #000;

// theme-colors
$primary: $green-dark;
$secondary: $green;
$tertiary: $yellow;
$quaternary: $green-light;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $yellow;
$link-decoration: underline;

// typography
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Ephesis&family=Merriweather:wght@300;400;700;900&display=swap");
$font-family-primary: "Merriweather", serif;
$font-family-secondary: "Abril Fatface", cursive;
$font-family-tertiary: "Ephesis", cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-color: $green-dark;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $green;
$matrix-color-secondary: $green-dark;
$matrix-color-tertiary: $yellow;
$matrix-color-quaternary: $green-light;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
