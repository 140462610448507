// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 255px;
				background: linear-gradient(0deg, rgba(#06262a, 0) 0%, rgba($black, 0.7) 100%);
			}

			.owl-caption {
				position: relative;
			}
		}
	}
}

// logo
.eyecatcher .logo {
	z-index: 4;
	position: absolute;
	left: 15px;
	bottom: 15px;
	width: 330px;

	@include media-breakpoint-up(xl) {
		bottom: 40px;
		width: 475px;
	}

	a {
		@extend .d-block;

		img {
			@extend .w-100;
		}
	}
}
