// footer
.footer {
    margin-top: 6vh;

    // footer-partners
    .footer-partners {
        padding: 30px 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin: 15px;
                flex: 1;
                text-align: center;
            }
        }
    }

    // footer-outro
    .footer-outro {
        @extend .bg-dark;

        // logo
        .logo {
            max-width: 427px;
            margin-bottom: 6vh;

            a {
                @extend .d-block;

                img {
                    @extend .w-100;
                }
            }
        }

        // two-columns
        .container-two-columns {
            .container-holder {
                .column {
                    &.one {
                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }
                    }

                    &.two {
                        @include media-breakpoint-up(lg) {
                            @include make-col(5);
                        }

                        // footer-text
                        .footer-text {
                            @include media-breakpoint-up(lg) {
                                position: absolute;
                                left: 3rem;
                                right: 1.5rem;
                            }

                            border: 3px dotted $white;
                            padding: 30px;

                            h3 {
                                font-weight: 400;
                                font-family: $font-family-secondary;
                            }

                            ul {
                                margin: 2rem 0;
                                padding: 0;
                                list-style: none;
                            }

                            // tel/mailto
                            a {
                                display: flex;
                                align-items: center;
                                color: $white;
                                font-size: 18px;
                                font-weight: 700;
                                text-decoration: none;

                                &::before {
                                    font-family: $font-awesome;
                                    display: inline-block;
                                    width: 35px;
                                    color: $tertiary;
                                    font-size: 24px;
                                    font-weight: 900;
                                }

                                &[href^="tel:"] {
                                    &::before {
                                        content: "\f095";
                                    }
                                }

                                &[href^="mailto:"] {
                                    &::before {
                                        content: "\f0e0";
                                    }
                                }

                                &:hover {
                                    color: $tertiary;
                                }
                            }
                        }
                    }
                }
            }
        }

        // three-columns
        .container-three-columns {
            margin-top: 30px;

            .container-holder {
                @include media-breakpoint-up(lg) {
                    @include make-col(8);
                }

                .column {

                    // footer-text
                    .footer-text {
                        h5 {
                            margin-bottom: 1rem;
                        }

                        ul {
                            @extend .list-unstyled;

                            li {
                                a {
                                    &:hover {
                                        color: $tertiary;
                                    }
                                }
                            }
                        }
                    }

                    // footer-logolink
                    .footer-logolink {
                        .list {
                            .list-item {
                                margin-right: 15px;
                                font-size: 30px;
                                line-height: 1;

                                .link {
                                    display: block;
                                    color: $tertiary;

                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // footer-copyright
    .footer-copyright {
        padding: 30px 0;
        background: $secondary;

        // footer-link
        .footer-link {
            .list {
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
					text-align: center;
                }

                .list-item {
                    font-size: 14px;

                    @include media-breakpoint-up(md) {
                        margin-right: 25px;
                    }

                    .link {
                        color: $white;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
