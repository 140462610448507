// btn
.btn {
	padding: 0.475rem 1rem;
	@include media-breakpoint-up(xl) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	border-radius: 4px;
	font-size: 14px;
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		background-color: $tertiary;
		border-color: $tertiary;

		&:hover {
			background-color: darken($tertiary, 15%);
			border-color: darken($tertiary, 15%);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	font-weight: 700;
	text-decoration: none;
	@extend .arrow-after;
	@extend .arrow-after-orange;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	text-decoration: none !important;
}