@import '~framework/assets/scss/V2.1/0_config/_functions';
@import '~framework/assets/scss/V2.1/0_config/_mixins';
@import '~framework/assets/scss/V2.1/0_config/_custom-variables';
@import './0_config/_variables';
@import '~framework/assets/scss/V2.1/0_config/_variables';
@import '~framework/assets/scss/V2.1/1_vendor/bootstrap';

// force html font size (prevent tommybooking conflict)
html {
	@import "~framework/assets/scss/V2.1/5_utilities/reset";
}

// theme within body class
body {
	// 2_layout
	@import "~framework/assets/scss/V2.1/2_layout/containers";

	// 3_components
	@import "~framework/assets/scss/V2.1/3_components/alert";
	@import "~framework/assets/scss/V2.1/3_components/buttons";
	@import "~framework/assets/scss/V2.1/3_components/card";
	@import "~framework/assets/scss/V2.1/3_components/carousel";
	@import "~framework/assets/scss/V2.1/3_components/cookie-consent";
	@import "~framework/assets/scss/V2.1/3_components/faq";
	@import "~framework/assets/scss/V2.1/3_components/form";
	@import "~framework/assets/scss/V2.1/3_components/lists";
	@import "~framework/assets/scss/V2.1/3_components/loading";
	@import "~framework/assets/scss/V2.1/3_components/recaptcha";
	@import "~framework/assets/scss/V2.1/3_components/timeslot";

	// 4_page_block
	@import '~framework/assets/scss/V2.1/4_page_block/_all';

	// 5_utilities
	@import "~framework/assets/scss/V2.1/5_utilities/animations";
	@import "~framework/assets/scss/V2.1/5_utilities/contextual";
	@import "~framework/assets/scss/V2.1/5_utilities/sticky";

	// app styling
	@import "./2_layout/base";
	@import "./2_layout/eyecatcher";
	@import "./2_layout/footer";
	@import "./2_layout/header";
	@import "./2_layout/menu";
	@import "./2_layout/sections";

	@import "./3_components/buttons";
	@import "./3_components/lists";

	@import "./6_tommy/tommy.book";
	@import "./6_tommy/tommy.matrix";
	@import "./6_tommy/tommy.mini-search-book";
	@import "./6_tommy/tommy.search-book";
}
