// headings
h1,
h2 {
	font-weight: 400;
	line-height: 1;
	font-family: $font-family-secondary;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// handwritten
.handwritten {
	color: $tertiary;
	@include font-size($h1-font-size);
	@include media-breakpoint-up(xl) {
		font-size: 48px;
	}
	line-height: 1;
	font-family: $font-family-tertiary;
}

// link-arrow
a.link-arrow {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	text-decoration: none !important;

	&::after {
		content: "";
		width: 28px;
		height: 20px;
		background: url("/images/arrow-white.svg");
		margin-left: 10px;
		transition: margin 0.5s;
	}

	&:hover {
		&::after {
			margin-left: 15px;
		}
	}
}

// arrow-after
.arrow-after {
	display: flex;
	align-items: center;

	&::after {
		content: "";
		width: 28px;
		height: 20px;
		background: url("/images/arrow-green.svg");
		margin-left: 10px;
		transition: margin 0.5s;
	}

	&:hover {
		&::after {
			margin-left: 15px;
		}
	}
}

.arrow-after-orange {
	&::after {
		background: url("/images/arrow-orange.svg");
	}
}

.arrow-after-white {
	&::after {
		background: url("/images/arrow-white.svg");
	}
}