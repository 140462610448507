// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	@extend .bg-dark;

	// one-column
	.container-one-column {
		.container-holder {
			max-width: 750px;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

&.home {
	.lead-section {

		// two-columns
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(7);
						}

						@include media-breakpoint-up(lg) {
							@include make-col(8);
						}

						@include media-breakpoint-up(xl) {
							flex: 0 0 840px;
							max-width: 840px;
						}

						h1 {
							@include media-breakpoint-up(xl) {
								font-size: 60px;
								line-height: 1;
							}

							text-shadow: $shadow;
						}
					}

					&.two {
						@include media-breakpoint-down(sm) {
							margin: 0 15px;
							flex: auto;
						}

						@include media-breakpoint-up(md) {
							@include make-col(5);
							padding-left: 15px !important;
						}

						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}

						@include media-breakpoint-up(xl) {
							flex: 0 0 300px;
							max-width: 300px;
						}

						border: 3px dotted $white;
						padding-top: 15px;
						padding-bottom: 15px;

						h3 {
							margin-bottom: 20px;
							font-weight: 400;
							font-family: $font-family-secondary;
						}
					}
				}
			}
		}
	}
}

&.accommodation-detail {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: none;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		.container-holder {
			max-width: 750px;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

}

// offcanvas-collection-section
// =========================================================================
.offcanvas-collection-section {
	margin: 6vh 0;
	overflow: hidden;

	// container
	.container-fluid {
		padding-right: 0;

		.container-holder {
			@include media-breakpoint-up(xl) {
				padding-left: calc((100vw / 2 - 575px));
			}
		}
	}

	// collection
	.collection {
		&.slider {
			.owl-stage-outer {
				padding-top: 20px;
			}

			.item {
				.card {
					border: none;
					border-radius: 0;
					background: none;
					overflow: visible;

					.card-body {
						margin-top: 25px;

						.card-caption {
							.card-title-link {
								.card-title {
									@extend .arrow-after;
								}
							}

							.card-title {
								font-size: 20px;
								color: $primary;
							}

							.card-subtitle {
								font-size: 16px;
								font-weight: 400;
							}

							.card-description {
								display: none;
							}
						}

						.card-buttons {
							display: none;
						}
					}
				}
			}

			.owl-item {
				&:nth-child(even) {
					.card-image {
						transform: rotate(-4deg);
						margin-bottom: 20px;
					}
				}

				&:nth-child(odd) {
					.card-image {
						margin-top: 20px;
						transform: rotate(2deg);
					}
				}
			}

			// owl-dots
			.owl-dots {
				display: flex;
				flex: 1;
				max-width: 1110px;
				height: 9px;
				margin-top: 20px;
				margin-right: 15px;
				padding: 0;
				border-radius: 10px;
				background: $secondary;

				.owl-dot {
					height: 5px;
					margin: 2px;
					border-radius: 10px;
					flex: 1;
					transition: 0.5s;

					&:hover {
						background: rgba($white, 0.5);
					}

					&.active {
						background: $white;
					}

					span {
						display: none;
					}
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// intro
	.intro {
		margin-bottom: 6vh;

		.container-holder {
			max-width: 750px;
			margin-bottom: 30px;

			h2 {
				@include font-size($h1-font-size);
			}
		}
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .bg-dark;
						border-color: $white;
						border-radius: 0;

						.card-image {
							&::before {
								content: "";
								position: absolute;
								top: 15px;
								left: 15px;
								right: 15px;
								bottom: 0;
								border: 2px dotted $white;
								border-bottom: 0;
							}
						}

						.card-body {
							position: relative;
							padding: 20px 35px 35px 35px;

							&::before {
								content: "";
								position: absolute;
								top: 0;
								left: 15px;
								right: 15px;
								bottom: 15px;
								border: 2px dotted $white;
								border-top: 0;
							}

							.card-caption {
								position: relative;

								.card-title {
									@include font-size($h2-font-size);
									font-weight: 400;
									font-family: $font-family-secondary;

									&:hover {
										color: $tertiary;
									}
								}

								.card-subtitle {
									color: $tertiary;
									font-size: 30px;
									font-weight: 400;
									font-family: $font-family-tertiary;
								}

							}

							.card-buttons {
								position: relative;
							}
						}
					}
				}
			}
		}
	}
}

// arrangements-collection-section
// =========================================================================
.arrangements-collection-section {

	// intro
	.intro {
		margin-bottom: 6vh;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $primary;
		}

		ul {
			@extend .list-unstyled;
			margin-bottom: 1.5rem;

			li {
				a.link-arrow {
					color: $white !important;
				}

				br {
					display: none;
				}
			}
		}

		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}
				}
			}
		}
	}

	// collection
	.collection {
		&.slider {
			.item {
				.card {
					@extend .bg-dark;
					border-color: $white;
					border-radius: 0;

					.card-image {
						&::before {
							content: "";
							position: absolute;
							top: 15px;
							left: 15px;
							right: 15px;
							bottom: 0;
							border: 2px dotted $white;
							border-bottom: 0;
						}

						.card-img {
							width: 100vw;
						}
					}

					.card-body {
						position: relative;
						padding: 25px 30px 40px 30px;

						@include media-breakpoint-up(xl) {
							flex-direction: row !important;
							padding-left: 40px;
							padding-right: 40px;
						}

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 15px;
							right: 15px;
							bottom: 15px;
							border: 2px dotted $white;
							border-top: 0;
						}

						.card-caption {
							position: relative;

							.card-title {
								@include font-size($h2-font-size);
								font-weight: 400;
								font-family: $font-family-secondary;

								&:hover {
									color: $tertiary;
								}
							}

							.card-subtitle {
								color: $tertiary;
								@include font-size($h1-font-size);
								font-weight: 400;
								line-height: 1;
								font-family: $font-family-tertiary;

								@include media-breakpoint-up(xl) {
									font-size: 48px;
								}
							}

						}

						.card-buttons {
							position: relative;

							@include media-breakpoint-up(xl) {
								margin-top: 0;
							}

							.card-btn {
								@extend .btn;
								@extend .btn-primary;

								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					.card {
						@extend .bg-dark;
						border-color: $white;
						border-radius: 0;

						.card-image {
							&::before {
								content: "";
								position: absolute;
								top: 15px;
								left: 15px;
								right: 15px;
								bottom: 0;
								border: 2px dotted $white;
								border-bottom: 0;
							}
						}

						.card-body {
							position: relative;
							padding: 20px 35px 35px 35px;

							&::before {
								content: "";
								position: absolute;
								top: 0;
								left: 15px;
								right: 15px;
								bottom: 15px;
								border: 2px dotted $white;
								border-top: 0;
							}

							.card-caption {
								position: relative;

								.card-title {
									&:hover {
										color: $tertiary;
									}
								}

								.card-subtitle {
									color: $tertiary;
									font-size: 30px;
									font-weight: 400;
									line-height: 1;
									font-family: $font-family-tertiary;
								}

							}

							.card-buttons {
								position: relative;
							}
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
